<!-- 热门商家 -->
<template>
    <div class="popularBusiness f-wrap">
        <div class="popularBusiness-modular">
            <div class="modular" v-for="i in GoodsClassList" :key="i.id">
                <div class="commodity">
                    <div class="commodity-info">
                        <div class="info-title">{{ i.name }}</div>
                        <div class="more">
                            <router-link :to="'/popularBusiness/commodity?id=' + i.id + '&title=' + i.name" v-if="$isMobile"><img src="@/assets/mobile/more.png"/></router-link>
                            <router-link  :to="'/popularBusiness/commodity?id=' + i.id + '&title=' + i.name" v-else>更多>></router-link>
                        </div>
                    </div>
                </div>
                <div class="business">
                    <div class="business-info" v-for="j in i.BrandBusinessList" :key="j.id" @click="businessInfo(j.id)">
                        <div class="business-face"><img :src="j.head_img" /></div>
                        <div class="business-name">{{ j.name }}</div>
                        <div class="business-wx" style="display:none">
                            <div class="business-wxName" @click="copy($event, j.wx)">微信：{{ j.wx }}</div>
                            <div class="business-wxCode" :style="j.wx_img ? '' : 'display:none !important'"><img :src="j.wx_img" /></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { indexApi } from '@/api/module.js';
export default {
    name: 'PopularBusiness',
    data() {
        return {
            GoodsClassList: [],
            // BrandBusinessList: [],
        };
    },
    //生命周期 - 创建完成（访问当前this实例）
    created() {},
    //生命周期 - 挂载完成（访问DOM元素）
    mounted() {
        this.requestGetGoodsClass();
    },
    methods: {
        // 获取分类
        requestGetGoodsClass() {
            const self = this;
            self.GoodsClassList = [];
            indexApi.getGoodsClass({ page: 1, size: 999 }).then((res) => {
                if (res.data.data && res.data.data.length > 0) {
                    const data = res.data.data;
                    self.GoodsClassList = data;
                    data.map((i, index) => {
                        self.requestGetBrandBusinessFn(i.id, index);
                    });
                }
            });
        },
        // 获取分类商家
        requestGetBrandBusinessFn(id, index) {
            const self = this;
            indexApi
                .getBrandBusinessFn({
                    size: 6,
                    goods_class_id: id,
                    page: 1,
                })
                .then((res) => {
                    if (res.data.data && res.data.data.length > 0) {
                        // self.GoodsClassList[index].BrandBusinessList = res.data.data;
                        this.$set(this.GoodsClassList[index], 'BrandBusinessList', res.data.data);
                    }
                });
        },
        // 商家跳转
        businessInfo(id) {
            this.$router.push({
                path: '/popularBusiness/business',
                query: { id: id },
            });
        },
        //复制
        copy(e, value) {
            e.stopPropagation();
            if (this.$isMobile) {
                let copyDom = document.createElement('input');
                copyDom.setAttribute('value', value);
                document.body.appendChild(copyDom);
                copyDom.select();
                document.execCommand('copy');
                document.body.removeChild(copyDom);
                this.$copy.show('微信号已复制，快打开微信添加吧！');
            }
        },
    },
    components: {},
};
</script>

<style lang="less" scoped>
/* @import url(); 引入css类 */
//PC端样式
.popularBusiness {
    .popularBusiness-modular {
        .commodity {
            margin-top: 30px;
            .commodity-info {
                height: 44px;
                line-height: 44px;
                font-weight: 400;
                font-family: Microsoft YaHei;
                border-bottom: 2px solid #f02420;
                .info-title {
                    width: 130px;
                    background: #f02420;
                    font-size: 24px;
                    color: #f1f1f1;
                    text-align: center;
                    display: inline-block;
                }
                .more {
                    float: right;
                    cursor: pointer;
                    a {
                        color: #666666;
                        font-size: 16px;
                    }
                }
            }
        }
        .business {
            text-align: left;
            .business-info {
                width: 187px;
                height: 200px;
                background: #ffffff;
                border-radius: 6px;
                display: inline-flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;
                margin-top: 24px;
                margin-right: 15px;
                cursor: pointer;
                &:nth-child(6n) {
                    margin-right: 0;
                }
                .business-face {
                    img {
                        width: 80px;
                        height: 80px;
                        border-radius: 50%;
                    }
                }
                .business-name {
                    height: 13px;
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #222222;
                    line-height: 13px;
                    margin: 13px 0 19px;
                }
                .business-wx {
                    width: 148px;
                    height: 30px;
                    background: #ffffff;
                    border: 1px solid #d2d2d2;
                    border-radius: 4px;
                    position: relative;
                    top: 0;
                    right: 0;
                    left: 0;
                    bottom: 0;
                    &:hover {
                        background: #ffe6e6;
                        border: 1px solid #f3cfce;
                        text-decoration: underline;
                        .business-wxCode {
                            display: block;
                        }
                    }
                    .business-wxName {
                        font-size: 12px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #666666;
                        line-height: 30px;
                        text-align: center;
                        cursor: pointer;
                    }
                    .business-wxCode {
                        display: none;
                        width: 191px;
                        height: 198px;
                        background: url('../assets/pc/round_bg@2x.png') no-repeat;
                        background-size: 100%;
                        position: absolute;
                        left: 50%;
                        transform: translateX(-50%);
                        img {
                            width: 150px;
                            height: 163px;
                            position: absolute;
                            left: 0;
                            right: 0;
                            top: 0;
                            bottom: 0;
                            margin: auto;
                        }
                    }
                }
            }
        }
    }
}

//移动端样式
@media screen and (max-width: 750px) {
    .popularBusiness {
        .popularBusiness-modular {
            .commodity {
                margin-top: 0;
                background: #eeeeee;
                .commodity-info {
                    padding: 0 15px;
                    border-bottom: none;
                    height: 40px;
                    line-height: 40px;

                    .info-title {
                        width: auto;
                        font-size: 16px;
                        font-family: PingFang SC;
                        font-weight: bold;
                        color: #222222;
                        background: none;
                    }
                    .more {
                        float: right;
                        img {
                            width: 39px;
                            height: 12px;
                            vertical-align: middle;
                        }
                        a{
                            font-size: 14px;
                        }
                    }
                }
            }
            .business {
                padding: 15px 15px 5px 15px;
                text-align: left;
                background: #ffffff;
                .business-info {
                    width: calc(33.333% - 5px);
                    height: 113px;
                    border: 1px solid #e5e5e5;
                    border-radius: 4px;

                    display: inline-flex;
                    justify-content: center;
                    flex-direction: column;
                    align-items: center;
                    margin: 0;
                    margin-right: 7.5px;
                    margin-bottom: 10px;
                    box-sizing: border-box;
                    &:nth-child(3n) {
                        margin-right: 0;
                    }
                    .business-face {
                        img {
                            width: 45px;
                            height: 45px;
                            border-radius: 50%;
                        }
                    }
                    .business-name {
                        height: 11px;
                        font-size: 11px;
                        font-family: Microsoft YaHei;
                        font-weight: 500;
                        color: #222222;
                        line-height: 11px;
                        margin: 10px 0 8px;
                    }
                    .business-wx {
                        width: 90px;
                        height: 20px;
                        background: #f02420;
                        border-radius: 2px;
                        border: none;
                        position: relative;
                        top: 0;
                        right: 0;
                        left: 0;
                        bottom: 0;
                        overflow: hidden;
                        &:hover {
                            background: #f02420;
                            border: none;
                            text-decoration: none;
                            .business-wxCode {
                                display: none;
                            }
                        }
                        .business-wxName {
                            font-size: 9px;
                            font-family: PingFang SC;
                            font-weight: 500;
                            color: #ffffff;
                            line-height: 20px;
                            white-space: nowrap;
                        }
                        .business-wxCode {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}
</style>
